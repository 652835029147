import React from 'react';
import 'bootstrap'
import vid from './home.mp4'
import Secabout from './secabout';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import svg1 from './iconservices/1.svg';
import svg2 from './iconservices/2.svg';
import svg3 from './iconservices/3.svg';
import Button from 'react-bootstrap/esm/Button';
import { contx } from '../../../App';
import { Link } from 'react-router-dom';
const Home = () => {
    const { lang } = React.useContext(contx);

    const data = lang === "en" ?  en:ar

    return (
        <div style={{ width: '100%' }}>

            <div style={{
                backgroundColor: '#005247',
                padding: 0,
                margin: 0,

            }}>


                <video width={'100%'} style={{ width: '75%', }} src={vid} autoPlay loop muted></video>
            </div>

            <Secabout />

            <div className='p-4 m-0' style={{ width: '100%', maxWidth: '1440', borderRadius: '10px', backgroundColor: '#F3F4F6'}}> 
                <h1>{data.ser.h}</h1>
                <Container>
                    <Row className="align-items-center p-5 " >
                        <Col className='d-flex justify-content-center mb-3'>
                            <div  style={{
                                width: '297px',
                                height: '296px',
                                flexShrink: '0',
                                borderRadius: '15px',
                                border: '3px solid #0B5846',
                                backgroundColor: 'rgba(11, 88, 70, 0.11)',

                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',}} >

                                
                                <img src={svg1} style={{ width: '80px', height: '80px' , marginBottom:'25px'}}  alt="" />
                                <h4>{data.ser.list[0]}</h4>
                            </div>

                        </Col>
                        <Col className='d-flex justify-content-center mb-3'>
                            <div style={{
                                width: '297px',
                                height: '296px',
                                flexShrink: '0',
                                borderRadius: '15px',
                                border: '3px solid #0B5846',
                                backgroundColor: 'rgba(11, 88, 70, 0.11)',

                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',}} >

                                
                                <img src={svg2} style={{ width: '80px', height: '80px' , marginBottom:'25px'}}  alt="" />
                                <h4>{data.ser.list[1]}</h4>
                            </div>

                        </Col>
                        <Col className='d-flex justify-content-center mb-3'>
                            <div style={{
                                width: '297px',
                                height: '296px',
                                flexShrink: '0',
                                borderRadius: '15px',
                                border: '3px solid #0B5846',
                                backgroundColor: 'rgba(11, 88, 70, 0.11)',

                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',}} >

                                
                                <img src={svg3} style={{ width: '80px', height: '80px' , marginBottom:'25px'}}  alt="" />
                                <h4>{data.ser.list[2]}</h4>
                            </div>

                        </Col>
                        
                    </Row>

                    <Link to='/Servises' style={{textDecoration:'none'}}>
                    <Button  style={{backgroundColor:'#005247',color:'white',border:'1px solid #005247'}}>View More</Button>
</Link>
                    
                </Container>
            </div>

                <div id='contact-map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26676.967900265732!2d44.42188031361099!3d33.30257724288395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1557819e0096b3af%3A0x6d200cdc90d69565!2z2KfZhNmF2K3Yt9ip!5e0!3m2!1sar!2siq!4v1704923022764!5m2!1sar!2siq" width="100%" height="450" style={{border:0,opacity:0.7}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
        </div>
    );
}

export default Home;


const ar ={
    ser:{
        h:'خدماتنا',
        list:[
            'تدريبات بمختلف المجالات الخاصة بقطاع الاعمال',
            `عمل دراسات الجدوى`,
            `تقديم الاستشارات والمشورات واعداد الخطط للشركات`
        ]
    }
}

const en ={
    ser:{
        h:'Our Services',
        list:[
            'Training in Various Business Sectors',
            `Providing Consultations and Planning for Companies `,
            `Feasibility Studies`
        ]
    }
}