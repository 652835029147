import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { contx } from '../../../App';

const Courses = () => {

    const { lang } = React.useContext(contx);

    const data = lang === "en" ?  dataen : dataar;
    return (
        <div>
            <Container>
                <Row>
                    {
                        data.map((item,index) => <Col md={6} lg={3} key={index} className='p-4'>
                            <Link to={'https://docs.google.com/forms/d/e/1FAIpQLSedPF3JHoJdi-sqYEt5M4NRDidgaicxHzZ2MMmR47XW40T_lA/viewform?pli=1'} style={{ textDecoration: 'none',color:'black' }} >

                            <div  style={{

                                boxShadow: '0px 0px 31px 0px rgba(0, 0, 0, 0.08)',
                            }}>
                                <img src={require(`./img/${index+1}.webp`)} style={{ width: '100%',clipPath: 'inset(0 0 7% 0)',height:'auto' }} alt="" />
                                <div style={{
                                    padding: '10px',
                                }} >
                                    <h4 style={{
                                        textAlign: 'center',
                                        margin: '6px 0',
                                    }}>{item}</h4>
                                    


                                    <button className='mt-2' style={{ backgroundColor: '#005247', color: 'white', border: '1px solid #005247',width:'100%' }}>
                                        {
                                            lang === "en" ?'Enroll Now':'التسجيل الآن'
                                        }
                                    </button>
                                </div>
                            </div></Link>



                        </Col>)
                    }
                </Row>
            </Container>
        </div>
    );
}

export default Courses;


const dataar = [
    `التسويق الرقمي`,
    `الموارد البشرية `,
    `التحول الرقمي`,
    `لغة الاعمال الانكليزية `,
    `دراسات الجدوى`,
    `معادلات الاكسل`,
    `المعيار المصرفي الخاص (معيار رقم 9 )`,
    `التصوير الاحترافي`,
    `محاسبة `,
    `تحليل مالي`,
    `مبيعات العقارية`,
    `المبيعات الشاملة `,
    `ادارة المطاعم والفنادق`,
    `تحليل البيانات`,
    `الاستثمار`,
    `ادارة الوقت والتنضيم`,
    `الفوركس`,
    `التنمية البشرية والمهارات الشخصية `,
    `مهارات الدبلماسية والاتكيت`,
    `المهارات اللغوية`,
    `المهارات الفنية (الحرف اليدوية)`,
    `ريادة الاعمال`,
    `تصميم الازياء`,
    `التصاميم الهندسية`,
    `المهارات الفنية لهندسات النفط`,
    `الامن السيبراني`,
    `كل ما يخص القطاع المصرفي`,
    `استراتيجسات العمل والتطوير`,
   
]

const dataen = [
    `Digital Marketing`,
    `Human Resources `,
    `Digital Transformation`,
    `English Language`,
    `Business Studies`,
    `Excel`,
    `Specialized Accounting (Number 9)`,
    `Professional Photography`,
    `Accounting`,
    `Financial Analysis`,
    `Real Estate Sales`,
    `Comprehensive Sales`,
    `Management of Restaurants and Hotels`,
    `Data Analysis`,
    `Investment`,
    `Time Management`,
    `Forex`,
    `Professional and Technical Skills`,
    `Diplomatic and Negotiation Skills`,
    `Language Skills`,
    `Technical Skills (Handwriting)`,
    `Business Development`,
    `Fashion Design`,
    `Engineering Design`,
    `Oil and Gas Engineering Technical Skills`,
    `Forensic Accounting`,
    `All sectors of the economy`,
    `Business Strategy and Development Planning`,

]